// BankingHeader_modal.js
import React, { useState } from 'react';
import styles from './BankingHeader_modal.module.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import ManagementModal from "./managementComponent/ManagementModal";
import DBInputModal from "../components/modal/DBInputModal";
import ManagerDBinputModal from "../components/modal/ManagerDBinputModal";

function BankingHeaderModal({ onClose }) {
    const navigate = useNavigate();

    const { t } = useTranslation();
    //로그파일 모달 변수
    const [logModalOpen, setLogModalOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [authLevel, setAuthLevel] = useState();
    const [userName, setUserName] = useState('');
    const formatDate = (dateString) => {
        if (!dateString) {
            return null;
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
    const [isManagerDBModalOpen, setIsManagerDBModalOpen] = useState(false);
    const [isManagementModalOpen, setIsManagementModalOpen] = useState(false);
    const [isDBInputModalOpen, setIsDBInputModalOpen] = useState(false);
    // 모달창 여는 함수
    const handleManagerDBModalOpen = () => {
        setIsManagerDBModalOpen(true);
    }
    // 모달창 닫는 함수
    const handleManagerDBModalClose = () => {
        setIsManagerDBModalOpen(false);
    }

    // 모달창 여는 함수
    const handleManagementModalOpen = () => {
        setIsManagementModalOpen(true);
    }
    // 모달창 닫는 함수
    const handleManagementModalClose = () => {
        setIsManagementModalOpen(false);
    }

    // 모달창 여는 함수
    const handleDBInputModalOpen = () => {
        setIsDBInputModalOpen(true);
    }
    // 모달창 닫는 함수
    const handleDBInputModalClose = () => {
        setIsDBInputModalOpen(false);
    }

    fetch('/api/getAuthLevel', { method: 'POST' })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error, status = ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data) {
                setUserName(data.userName);
                setAuthLevel(data.authLevel);
            }
        })
        .catch(error => console.log(error));

    const handleDownload = (dataToDownload) => {
        // accessTime을 formatDate로 형변환
        dataToDownload.forEach(row => {
            row.accessTime = formatDate(row.accessTime);
        });

        const ws = utils.json_to_sheet(dataToDownload);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "LogData");  // "LogData"는 워크시트의 이름입니다.

        const wbout = write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        saveAs(blob, 'logData.xlsx');  // 'logData.xlsx'는 다운로드될 파일의 이름입니다.
    };

    const handleLogDownload = () => {
        const reqOption = {
            method: 'post',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ startDate, endDate })
        };
        fetch('/api/getAllLogData', reqOption)
            .then(response => response.json())
            .then(data => {
                if (data?.data) {
                    handleDownload(data?.data);
                }
                if (data?.message) {
                    alert(data?.message);
                }
            });
    }

    const handleLogout = () => {
        fetch('/api/logout', { method: 'POST' })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                if (data === true) {
                    // setIsLoggedIn(false);
                    navigate('/');
                    window.location.reload();
                }
            })
            .catch(error => console.log(error));
    };
    return (
        <div className={styles.modalBackground} onClick={onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                {/* 관리자용 데이터 업로드 */}
                {
                    (userName === '박준수') &&
                    (
                        <>
                            <p onClick={handleManagerDBModalOpen} className={styles.userMenu}>{t('관리자 db 업데이트')}</p>
                            {isManagerDBModalOpen && (
                                <ManagerDBinputModal
                                    isOpen={isManagerDBModalOpen}  // 추가
                                    onClose={handleManagerDBModalClose}
                                    onUpload={(file) => {
                                        console.log("업로드된 파일:", file);
                                    }}
                                />
                            )}

                        </>
                    )
                }
                {/* 모달 내용 */}
                {
                    (authLevel !== null && authLevel >= 3) &&
                    (
                        <>
                            <p onClick={handleDBInputModalOpen} className={styles.userMenu}>{t('DB Input')}</p>
                            {isDBInputModalOpen && <DBInputModal onClose={handleDBInputModalClose} />}
                            <p onClick={handleManagementModalOpen} className={styles.userMenu}>{t('관리 페이지')}</p>
                            {isManagementModalOpen && <ManagementModal onClose={handleManagementModalClose} />}
                            <p onClick={() => setLogModalOpen(true)} className={styles.userMenu}>{t('로그 파일 다운로드')}</p>
                        </>
                    )
                }
                <Modal
                    isOpen={logModalOpen}
                    onRequestClose={() => setLogModalOpen(false)}
                    className={styles.logModalContent}
                    overlayClassName={styles.logModalOverlay}
                >
                    <h2 className={styles.logModalHeader}>{t('로그 파일 다운로드')}</h2>
                    <label className={styles.logModalLabel}>
                        {t('시작 날짜')}:
                        <input
                            type="date"
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}
                            className={styles.logModalInput}
                        />
                    </label>
                    <label className={styles.logModalLabel}>
                        {t('종료 날짜')}:
                        <input
                            type="date"
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}
                            className={styles.logModalInput}
                        />
                    </label>
                    <button onClick={handleLogDownload} className={styles.logModalButton}>{t('다운로드')}</button>
                    <button onClick={() => setLogModalOpen(false)} className={styles.logModalButton}>{t('닫기')}</button>
                </Modal>
                <p onClick={handleLogout} className={styles.userMenu}>{t('로그아웃')}</p>
            </div>
        </div>
    );
}

export default BankingHeaderModal;
