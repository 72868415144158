import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import styles from './DraftInfoModal.module.css';
import DraftBankingDetailInfoComponent from './DraftBankingDetailInfoComponent';
import DraftOrganoidDetailInfoComponent from './DraftOrganoidDetailInfoComponent';
import DraftPatientDetailInfoComponent from './DraftPatientDetailInfoComponent';

function DraftInfoModal({ show, onClose, selectedDraft, reDrawView }) {
    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleString('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const cancelDraft = (draftDocumentId) => {
        if (window.confirm(t("기안을 취소하시겠습니까?"))) {
            let reqOption = {
                method: "post",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ draftDocumentId: draftDocumentId }),
            };

            fetch("/api/cancelDraft", reqOption)
                .then((response) => response.json())
                .then(data => {
                    alert(data.message);
                    onClose();
                    reDrawView();
                });
        }
    };

    const rejectDraft = (draftDocumentId) => {
        const rejectDecision = window.confirm(t("반려하시겠습니까?"));

        if (rejectDecision) {
            let reqOption = {
                method: "post",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ draftDocumentId: draftDocumentId, decision: 'rejected' }),
            };

            fetch("/api/authorizeDraft", reqOption)
                .then((response) => response.json())
                .then(data => {
                    alert(data.message);
                    onClose();
                    reDrawView();
                });
        }
    };


    const approveDraft = (draftDocumentId, draftOpinion) => {
        const authDecision = window.confirm(t("승인하시겠습니까?"));

        if (authDecision) {
            let reqOption = {
                method: "post",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ draftDocumentId: draftDocumentId, decision: 'approved' }),
            };

            fetch("/api/authorizeDraft", reqOption)
                .then((response) => response.json())
                .then(data => {
                    alert(data.message);
                    onClose();
                    reDrawView();
                });
        }
    };


    function hashmark(str) {
        if (str) {
            return (str.replace('hashmark', '#'));
        } else {
            return str;
        }
    }

    const list = {
        Banking_Info: "Working Stock",
        Organoid_Info: "Master Stock",
        Patient_Info: "Patient info",
        StorageList: "Storage",
    };

    const [userName, setUserName] = useState('');
    const [userInfo, setUserInfo] = useState({});

    // 현재 유저 정보 가져오기
    useEffect(() => {
        let reqOption = {
            method: "post",
            headers: { "content-type": "application/json" }
        };

        fetch("/api/getLoginedUserInfo", reqOption)
            .then((response) => response.json())
            .then(data => {
                setUserName(data.data[0]?.userName);
                setUserInfo(data?.data[0]);
            });
    }, []);

    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [show]);

    if (!show) {
        return null;
    }

    return (
        <div className={styles.popupBackground} onClick={onClose}>
            <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                <div className={styles.draftInfoLeftTab}>
                    <div className={styles.draftInfoTitle}>
                        {
                            selectedDraft.draftCategory && (
                                <span>
                                    {selectedDraft.draftCategory === 'Organoid_Info' && t('오가노이드')}
                                    {selectedDraft.draftCategory === 'Banking_Info' && t('뱅킹 스탁')}
                                    {selectedDraft.draftCategory === 'Patient_Info' && t('환자 정보')}
                                    {(selectedDraft.draftCategory === 'Light_Microscope_picture_data' || selectedDraft.draftCategory === 'Pathology_Picture_picture_data') && t('이미지')}
                                </span>
                            )
                        }
                        {
                            selectedDraft.authLevel && (
                                <span>
                                    &nbsp;
                                    {/* (1 : 읽기, 2 : 쓰기, 3 : 수정, 4 : 삭제, 5 : 반입, 6 : 반출) */}
                                    {selectedDraft.authLevel === 1 && t('읽기')}
                                    {selectedDraft.authLevel === 2 && t('추가')}
                                    {selectedDraft.authLevel === 3 && t('수정')}
                                    {selectedDraft.authLevel === 4 && t('삭제')}
                                    {selectedDraft.authLevel === 5 && t('반입')}
                                    {selectedDraft.authLevel === 6 && t('반출')}
                                </span>

                            )

                        }
                        &nbsp;{t('신청')}
                        {selectedDraft?.authorizedStatus && (
                            <span className={`${styles.approvalIcon} ${selectedDraft.authorizedStatus === 'approved' ? styles.approved :
                                selectedDraft.authorizedStatus === 'pending' ? styles.pending :
                                    selectedDraft.authorizedStatus === 'canceled' ? styles.canceled :
                                        selectedDraft.authorizedStatus === 'rejected' ? styles.rejected : ''
                                }`}>
                                {selectedDraft.authorizedStatus === 'approved' && t('승인')}
                                {selectedDraft.authorizedStatus === 'pending' && t('대기중')}
                                {selectedDraft.authorizedStatus === 'canceled' && t('취소')}
                                {selectedDraft.authorizedStatus === 'rejected' && t('반려')}
                            </span>
                        )}
                    </div>
                    <div className={styles.draftInfoDiv}>
                        <div className={styles.draftInfoColumn}>{t('기안 ID')}</div>
                        <div className={styles.draftInfoData}>{selectedDraft.draftDocumentId}</div>
                        <div className={styles.draftInfoColumn}>{t('날짜/시간')}</div>
                        <div className={styles.draftInfoData}>{formatDateTime(selectedDraft.regTime)}</div>
                        <div className={styles.draftInfoColumn}>{t('기안 타겟')}</div>
                        <div className={styles.draftInfoData}>{hashmark(selectedDraft.targetKey)}</div>
                        <div className={styles.draftInfoColumn}>{t('분류')}</div>
                        <div className={styles.draftInfoData}>{list[selectedDraft.draftCategory]}</div>
                        <div className={styles.draftInfoColumn}>{t('이름')}</div>
                        <div className={styles.draftInfoData}>{selectedDraft.draftUserName}</div>
                        <div className={styles.draftInfoColumn}>{t('팀명')}</div>
                        <div className={styles.draftInfoData}>{selectedDraft.draftUserTeamName}</div>
                        <div className={styles.draftInfoColumn}>{t('기안 의견')}</div>
                        <div className={styles.draftInfoData}>
                            {selectedDraft.draftOpinion?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    {selectedDraft.draftCategory === 'Organoid_Info' &&
                        <DraftOrganoidDetailInfoComponent Key={selectedDraft.targetKey} selectedDraft={selectedDraft} />
                    }
                    {/* selectedDraft */}
                    {selectedDraft.draftCategory === 'Banking_Info' &&
                        <DraftBankingDetailInfoComponent Key={selectedDraft.targetKey} selectedDraft={selectedDraft} />
                    }
                    {selectedDraft.draftCategory === 'Patient_Info' &&
                        <DraftPatientDetailInfoComponent Key={selectedDraft.targetKey} selectedDraft={selectedDraft} />
                    }
                </div>
                <div className={styles.draftInfoRightTab}>
                    <button className={styles.closeButton} onClick={onClose}>&times;</button>
                    <div className={styles.approval}>승인</div>
                    <div className={styles.authorizedPersonInfo}>
                        <span>
                            {selectedDraft?.authorizedPersonName}
                        </span>
                        {selectedDraft?.authorizedStatus && (
                            <span className={`${styles.approvalIcon} ${selectedDraft.authorizedStatus === 'approved' ? styles.approved :
                                selectedDraft.authorizedStatus === 'pending' ? styles.pending :
                                    selectedDraft.authorizedStatus === 'canceled' ? styles.canceled :
                                        selectedDraft.authorizedStatus === 'rejected' ? styles.rejected : ''
                                }`}>
                                {selectedDraft.authorizedStatus === 'approved' && t('승인')}
                                {selectedDraft.authorizedStatus === 'pending' && t('대기중')}
                                {selectedDraft.authorizedStatus === 'canceled' && t('취소')}
                                {selectedDraft.authorizedStatus === 'rejected' && t('반려')}
                            </span>
                        )}
                    </div>
                    <div className={styles.authTeamName}>
                        {selectedDraft?.authorizedPersonTeamName}
                    </div>
                    <div className={styles.draftButtonGroup}>
                        {
                            selectedDraft?.authorizedStatus === 'pending' && (userName === selectedDraft?.authorizedPersonName || userInfo.authLevel === 3) ? (
                                <>
                                    <button className={styles.approvalButton} onClick={() => approveDraft(selectedDraft.draftDocumentId)}>{t('승인')}</button>
                                    <button className={styles.rejectButton} onClick={() => rejectDraft(selectedDraft.draftDocumentId)}>{t('반려')}</button>
                                    <button className={styles.cancelButton} onClick={() => cancelDraft(selectedDraft.draftDocumentId)}>{t('취소')}</button>
                                </>
                            ) : (
                                selectedDraft.draftUserName && selectedDraft.draftUserName === userName && selectedDraft?.authorizedStatus === 'pending' && (
                                    <button className={styles.cancelButton} onClick={() => cancelDraft(selectedDraft.draftDocumentId)}>{t('취소')}</button>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default DraftInfoModal;
