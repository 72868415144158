// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DraftPatientDetailInfoComponent_main__Dkb36 {
    height: calc(90vh - 440px);
    overflow-y: auto;
    padding-top: 30px;
    padding-left: 20px;
}

.DraftPatientDetailInfoComponent_draftTitle__WKyaE {
    font-weight: 600;
    font-size: 1.5rem;
    color: #7B878C;
    border-bottom: 3px solid #DEDEDE;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.DraftPatientDetailInfoComponent_infoContainer__hNSrF {
    display: block; /* 항목을 수직으로 나열 */
    padding-top: 10px;
}

.DraftPatientDetailInfoComponent_fieldRow__ITWCX {
    display: block; /* 각 필드를 블록 단위로 배치 */
    margin-bottom: 15px; /* 항목 간 간격 */
    border-bottom: 1px solid #eaeaea; /* 각 항목 구분을 위한 경계선 */
    padding-bottom: 10px;
}

.DraftPatientDetailInfoComponent_fieldLabel__zcjPN {
    font-weight: bold;
    font-size: 1rem;
    color: #7B878C;
    margin-bottom: 5px; /* 레이블과 값 사이 간격 */
}

.DraftPatientDetailInfoComponent_fieldValue__NeTmC {
    font-size: 1rem;
    color: #333;
    word-wrap: break-word; /* 긴 텍스트 줄바꿈 */
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/DraftPatientDetailInfoComponent.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,gCAAgC;IAChC,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,cAAc,EAAE,gBAAgB;IAChC,iBAAiB;AACrB;;AAEA;IACI,cAAc,EAAE,oBAAoB;IACpC,mBAAmB,EAAE,YAAY;IACjC,gCAAgC,EAAE,oBAAoB;IACtD,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,kBAAkB,EAAE,iBAAiB;AACzC;;AAEA;IACI,eAAe;IACf,WAAW;IACX,qBAAqB,EAAE,cAAc;AACzC","sourcesContent":[".main {\n    height: calc(90vh - 440px);\n    overflow-y: auto;\n    padding-top: 30px;\n    padding-left: 20px;\n}\n\n.draftTitle {\n    font-weight: 600;\n    font-size: 1.5rem;\n    color: #7B878C;\n    border-bottom: 3px solid #DEDEDE;\n    margin-bottom: 20px;\n    padding-bottom: 10px;\n}\n\n.infoContainer {\n    display: block; /* 항목을 수직으로 나열 */\n    padding-top: 10px;\n}\n\n.fieldRow {\n    display: block; /* 각 필드를 블록 단위로 배치 */\n    margin-bottom: 15px; /* 항목 간 간격 */\n    border-bottom: 1px solid #eaeaea; /* 각 항목 구분을 위한 경계선 */\n    padding-bottom: 10px;\n}\n\n.fieldLabel {\n    font-weight: bold;\n    font-size: 1rem;\n    color: #7B878C;\n    margin-bottom: 5px; /* 레이블과 값 사이 간격 */\n}\n\n.fieldValue {\n    font-size: 1rem;\n    color: #333;\n    word-wrap: break-word; /* 긴 텍스트 줄바꿈 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `DraftPatientDetailInfoComponent_main__Dkb36`,
	"draftTitle": `DraftPatientDetailInfoComponent_draftTitle__WKyaE`,
	"infoContainer": `DraftPatientDetailInfoComponent_infoContainer__hNSrF`,
	"fieldRow": `DraftPatientDetailInfoComponent_fieldRow__ITWCX`,
	"fieldLabel": `DraftPatientDetailInfoComponent_fieldLabel__zcjPN`,
	"fieldValue": `DraftPatientDetailInfoComponent_fieldValue__NeTmC`
};
export default ___CSS_LOADER_EXPORT___;
