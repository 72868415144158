// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ManagerDBinputModal.module.css */

.ManagerDBinputModal_ManagerDBinputModal_modalOverlay__OnXFE {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_modalContent__iSnXY {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_h2__sGkx1 {
    margin-bottom: 16px;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_inputFile__AcTks {
    margin: 12px 0;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_buttonGroup__2A5bN {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_button__vTXx5 {
    padding: 8px 16px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_button__vTXx5:first-of-type {
    background: #007bff;
    color: white;
  }
  
  .ManagerDBinputModal_ManagerDBinputModal_button__vTXx5:last-of-type {
    background: #dc3545;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/modal/ManagerDBinputModal.module.css"],"names":[],"mappings":"AAAA,mCAAmC;;AAEnC;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,wCAAwC;IACxC,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,YAAY;EACd","sourcesContent":["/* ManagerDBinputModal.module.css */\n\n.ManagerDBinputModal_modalOverlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  \n  .ManagerDBinputModal_modalContent {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    width: 400px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .ManagerDBinputModal_h2 {\n    margin-bottom: 16px;\n  }\n  \n  .ManagerDBinputModal_inputFile {\n    margin: 12px 0;\n  }\n  \n  .ManagerDBinputModal_buttonGroup {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 16px;\n  }\n  \n  .ManagerDBinputModal_button {\n    padding: 8px 16px;\n    font-size: 16px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .ManagerDBinputModal_button:first-of-type {\n    background: #007bff;\n    color: white;\n  }\n  \n  .ManagerDBinputModal_button:last-of-type {\n    background: #dc3545;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ManagerDBinputModal_modalOverlay": `ManagerDBinputModal_ManagerDBinputModal_modalOverlay__OnXFE`,
	"ManagerDBinputModal_modalContent": `ManagerDBinputModal_ManagerDBinputModal_modalContent__iSnXY`,
	"ManagerDBinputModal_h2": `ManagerDBinputModal_ManagerDBinputModal_h2__sGkx1`,
	"ManagerDBinputModal_inputFile": `ManagerDBinputModal_ManagerDBinputModal_inputFile__AcTks`,
	"ManagerDBinputModal_buttonGroup": `ManagerDBinputModal_ManagerDBinputModal_buttonGroup__2A5bN`,
	"ManagerDBinputModal_button": `ManagerDBinputModal_ManagerDBinputModal_button__vTXx5`
};
export default ___CSS_LOADER_EXPORT___;
