// ManagerDBinputModal.js
import React, { useState } from 'react';
import styles from './ManagerDBinputModal.module.css';

const ManagerDBinputModal = ({ isOpen, onClose, onUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadType, setUploadType] = useState('default');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!selectedFile || uploadType === 'default') {
      alert('파일 및 업로드 유형을 선택하세요.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    let apiEndpoint = '/api/uploadFusionExcel';

    if (uploadType === 'rnaInformation') {
      apiEndpoint = '/api/uploadRNAInformation';
    } else if (uploadType === 'rnaExpressionLung') {
      apiEndpoint = '/api/uploadRNAExpressionJson';
    }

    fetch(apiEndpoint, {
        method: 'POST',
        body: formData,
    })
        .then((response) => {
            if (!response.ok) {
                console.error("서버 응답 오류:", response.statusText);
                throw new Error(`HTTP Error, Status = ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            alert(data.message || '업로드 성공!');
            setSelectedFile(null);
            onClose();
        })
        .catch((error) => {
            console.error('업로드 오류:', error);
            alert('파일 업로드에 실패했습니다.');
        });
  };

  if (!isOpen) return null;

  return (
    <div className={styles.ManagerDBinputModal_modalOverlay}>
      <div className={styles.ManagerDBinputModal_modalContent}>
        <h2 className={styles.ManagerDBinputModal_h2}>DB 일괄 업로드</h2>

        <label className={styles.ManagerDBinputModal_label}>업로드 유형 선택:</label>
        <select
          className={styles.ManagerDBinputModal_select}
          value={uploadType}
          onChange={(e) => setUploadType(e.target.value)}
        >
          <option value="default">파일 유형 선택</option>
          <option value="fusionExcel">Fusion Excel 업로드</option>
          <option value="rnaInformation">RNA 정보 업로드</option>
          <option value="rnaExpressionLung">RNA Expression Lung 업로드</option>
        </select>

        <input
          type="file"
          accept="*/*"
          className={styles.ManagerDBinputModal_inputFile}
          onChange={handleFileChange}
        />
        
        <div className={styles.ManagerDBinputModal_buttonGroup}>
          <button 
            className={styles.ManagerDBinputModal_button} 
            onClick={handleUpload}>업로드</button>
          <button 
            className={styles.ManagerDBinputModal_button} 
            onClick={onClose}>취소</button>
        </div>
      </div>
    </div>
  );
};

export default ManagerDBinputModal;
