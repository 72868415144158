import React, { useState, useEffect } from 'react';
import styles from './DraftOrganoidDetailInfoComponent.module.css';

function DraftOrganoidDetailInfoComponent(props) {
    const [organoidData, setOrganoidData] = useState();

    const hashmarkToSharp = (str) => {
        return str.replace('hashmark', '#');
    };

    useEffect(() => {
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                param: props.Key,
            }),
        };

        fetch('/api/getOrganoidInfo_noLog', reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    setOrganoidData(data[0]);
                }
            });
    }, []);

    const renderField = (label, value) => (
        <div className={styles.fieldRow}>
            <div className={styles.fieldLabel}>{label}</div>
            <div className={styles.fieldValue}>{value}</div>
        </div>
    );

    return (
        <>
            {organoidData && (
                <div className={styles.main}>
                    <div className={styles.draftTitle}>Organoid Information</div>
                    <div className={styles.infoContainer}>
                        {renderField('GBCC Code', hashmarkToSharp(organoidData.Key))}
                        {renderField('Disease Type', organoidData.Type)}
                        {renderField('Subtype', organoidData.Subtype)}
                        {renderField('Sample Source', organoidData.sampleSource)}
                        {renderField('Sample Type', organoidData.SampleType)}
                        {renderField('Site', organoidData.site)}
                        {renderField('Surgery Date', organoidData.surgeryDate)}
                        {renderField('Establishment No.', organoidData.establishmentNo)}
                        {renderField('N/T Pair', organoidData.Pair)}
                        {renderField('WES', organoidData.WES)}
                        {renderField('RNA Seq.', organoidData.RNASeq)}
                        {renderField(
                            'Mutation Information',
                            organoidData.MutationInformation?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))
                        )}
                        {renderField(
                            'PDO Annotation',
                            organoidData.MSS?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftOrganoidDetailInfoComponent;
