import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import MultiLargeFileUploads from '../../components/modal/MultiLargeFileUploads';
import styles from './RNAContent.module.css';

const TABS = {
    0: 'Lung',
    1: 'Gastric',
    2: 'Colon',
    3: 'Pancrease',
    4: 'Ovary',
    5: 'Bladder',
};

function RNAContent() {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isDownloading, setIsDownloading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [userName, setUserName] = useState('');
    const [authLevel, setAuthLevel] = useState('');
    const [showProcessedOutput, setShowProcessedOutput] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);

    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('/api/files?type=RNA');
                setFileList(response.data.files || []);
            } catch (error) {
                console.error('Error fetching RNA file list:', error);
            }
        };

        const fetchAuthLevel = () => {
            fetch('/api/getAuthLevel', { method: 'POST' })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error, status = ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data) {
                        setUserName(data.userName);
                        setAuthLevel(data.authLevel);
                    }
                })
                .catch(error => console.log(error));
        };

        fetchFiles();
        fetchAuthLevel();
    }, []);

    const handleCheckboxChange = (fileName) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.includes(fileName)
                ? prevSelectedFiles.filter((file) => file !== fileName)
                : [...prevSelectedFiles, fileName]
        );
    };

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
        setSelectedFiles([]); // 탭 이동 시 선택한 파일 초기화
        setIsAllSelected(false); // 전체 선택 초기화
        setShowProcessedOutput(false); // Processed Output 상태 해제
    };

    const handleSelectAll = () => {
        const tabFiles = fileList.filter((file) =>
            parseInt(file.charAt(2), 10) === activeTab
        );
        if (isAllSelected) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(tabFiles);
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleDownload = async () => {
        if (selectedFiles.length === 0) {
            alert(t('다운로드할 파일을 선택하세요.'));
            return;
        }

        setIsDownloading(true);

        try {
            const response = await axios.post(
                '/api/download',
                { type: 'RNA', files: selectedFiles },
                {
                    responseType: 'blob',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (!response.data || response.data.size === 0) {
                throw new Error('압축된 파일이 비어 있습니다.');
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'RNA_files.zip');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading RNA files:', error);
            alert(t(`파일 다운로드 중 오류가 발생했습니다: ${error.message}`));
        } finally {
            setIsDownloading(false);
        }
    };

    const handleProcessRNAExpression = async () => {
        if (selectedFiles.length === 0) {
            alert(t('처리할 샘플을 선택하세요.'));
            return;
        }
    
        // NO 또는 NT가 포함된 파일 검사
        const invalidFiles = selectedFiles.filter((file) => /NO|NT/i.test(file));
        if (invalidFiles.length > 0) {
            alert(t(`다음 파일에는 NO 또는 NT가 포함되어 처리할 수 없습니다:\n${invalidFiles.join(', ')}`));
            return;
        }
    
        setIsProcessing(true);
    
        try {
            // 현재 활성화된 탭에 따라 샘플 구분
            const currentTabLabel = TABS[activeTab]; // 현재 탭의 레이블 가져오기
    
            const lungColonSamples = selectedFiles.filter(
                (file) => currentTabLabel === 'Lung' || currentTabLabel === 'Colon'
            );
            const otherSamples = selectedFiles.filter(
                (file) => currentTabLabel !== 'Lung' && currentTabLabel !== 'Colon'
            );
    
            // Lung과 Colon 처리 (다운로드 수행)
            if (lungColonSamples.length > 0) {
                const response = await axios.post(
                    '/api/rna-expression/direct-fetch',
                    { samples: lungColonSamples },
                    { responseType: 'blob' }
                );
    
                if (response.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'RNAExpression_LungColon.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }
            }
    
            // 기타 샘플 처리 (샘플 이름 및 사용자 정보 전송)
            if (otherSamples.length > 0) {
                await axios.post('/api/rna-expression/process-and-fetch', {
                    samples: otherSamples,
                    userName: userName, // 사용자 이름 추가
                });
                alert(t('기타 샘플이 성공적으로 처리되었습니다.'));
            }
        } catch (error) {
            console.error('RNA expression 처리 실패:', error);
            alert(t(`RNA expression 처리 중 오류가 발생했습니다: ${error.message}`));
        } finally {
            setIsProcessing(false);
        }
    };
    

    const handleShowProcessedOutput = async () => {
        try {
            const response = await axios.get('/api/files/?type=ProcessedOutput');
            setProcessedFiles(response.data.files || []);
            setShowProcessedOutput(true);
        } catch (error) {
            console.error('Error fetching processed files:', error);
            alert(t('Processed Output 파일 목록을 가져오는 중 오류가 발생했습니다.'));
        }
    };

    const filteredFileList = fileList.filter((file) => {
        const tabKey = parseInt(file.charAt(2), 10);
        return tabKey === activeTab && file.toLowerCase().includes(filterText.toLowerCase());
    });

    const totalFilesInTab = fileList.filter((file) =>
        parseInt(file.charAt(2), 10) === activeTab
    ).length;

    const selectedFilesCount = selectedFiles.length;
    const filteredFilesCount = filteredFileList.length;

    return (
        <div className={styles.rnaContent}>
            <h2>{t('RNA 파일 목록')}</h2>

            <div className={styles.tabContainer}>
                {Object.entries(TABS).map(([key, label]) => (
                    <button
                        key={key}
                        className={`${styles.tabButton} ${activeTab === parseInt(key, 10) ? styles.active : ''}`}
                        onClick={() => handleTabChange(parseInt(key, 10))} // 탭 이동 시 초기화
                    >
                        {t(label)}
                    </button>
                ))}
            </div>

            <div>
                <button
                    onClick={handleSelectAll}
                    className={styles.selectAllButton}
                >
                    {isAllSelected ? t('전체 선택 해제') : t('전체 선택')}
                </button>
            </div>

            <button
                className={styles.uploadButton}
                onClick={() => setShowUploadModal(true)}
            >
                {t('파일 업로드')}
            </button>

            <input
                type="text"
                placeholder={t('파일 이름으로 필터링')}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className={styles.filterInput}
            />
            <div className={styles.fileStats}>
                <span>{t('전체 파일')}: {totalFilesInTab}</span>
                <span> &nbsp; &nbsp;{t('필터링된 파일')}: {filteredFilesCount}</span>
                <span> &nbsp; &nbsp;{t('선택된 파일')}: {selectedFilesCount}</span>
            </div>
            {/* <span>필터링 갯수 : {filteredFileList.length} 전체 갯수 : {fileList.length}</span> */}

            {!showProcessedOutput && (
                <ul className={styles.fileList}>
                    {filteredFileList.map((file) => (
                        <li key={file}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectedFiles.includes(file)}
                                    onChange={() => handleCheckboxChange(file)}
                                />
                                {file}
                            </label>
                        </li>
                    ))}
                </ul>
            )}

            <div className={styles.buttonGroup}>
                <button
                    onClick={handleDownload}
                    disabled={isDownloading}
                    className={isDownloading ? styles.disabledButton : ''}
                >
                    {isDownloading
                        ? t('다운로드 중...')
                        : t('Fusion 분석')}
                </button>

                <button
                    onClick={handleProcessRNAExpression}
                    disabled={isProcessing}
                    className={isProcessing ? styles.disabledButton : ''}
                >
                    {isProcessing
                        ? t('RNA expression 처리 중...')
                        : t('Expression 분석')}
                </button>
                <button
                    className={styles.processedOutputButton}
                    onClick={handleShowProcessedOutput}
                >
                    {t('Expression 분석 결과 보기')}
                </button>
            </div>
            {showProcessedOutput && (
                <div className={styles.processedOutputContainer}>
                    <h3>{t('Processed Output 파일 목록')}</h3>
                    <ul className={styles.fileList}>
                        {processedFiles.map((file) => (
                            <li key={file}>
                                <a href={`/api/files/processed/download/${file}`} download>{file}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {showUploadModal && (
                <MultiLargeFileUploads
                    onClose={() => setShowUploadModal(false)}
                />
            )}
        </div>
    );
}

export default RNAContent;
