import styles from './BankingStatistics.module.css';
import React, { useState, useEffect } from 'react';

function BankingStatistics(props) {
    const [isSelectedFirst, setIsSelectedFirst] = useState(true);
    const [organoidStatusToBox, setOrganoidStatusToBox] = useState([]);
    const [organoidStatusToLine, setOrganoidStatusToLine] = useState([]);
    const [selectedLine, setSelectedLine] = useState(null);

    useEffect(() => {
        const reqOption = {
            method: "post",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ vesselNo: props.vessel })
        };

        // Box 단위 데이터 가져오기
        fetch("/api/getOrganoidStatusByVesselNo", reqOption)
            .then(response => response.json())
            .then(data => setOrganoidStatusToBox(data));

        // Line 단위 데이터 가져오기
        fetch("/api/getOrganoidStatusByVesselNoToLine", reqOption)
            .then(response => response.json())
            .then(data => setOrganoidStatusToLine(data));
    }, [props.vessel]);

    // Line 선택 핸들러
    const handleLineSelect = line => {
        setSelectedLine(line);
    };

    return (
        <div className={styles.outerBox}>
            <div className={styles.masterWorkingTab}>
                {/* Master Stock 탭 */}
                <div className={`${isSelectedFirst ? styles.boxNameFront : styles.boxNameBack}`}
                    onClick={() => {
                        setIsSelectedFirst(true);
                        setSelectedLine(null);
                    }}
                >
                    Master Stock
                </div>
                {/* Working Stock 탭 */}
                <div className={`${!isSelectedFirst ? styles.boxNameFront : styles.boxNameBack}`}
                    onClick={() => {
                        setIsSelectedFirst(false);
                        setSelectedLine(null);
                    }}
                >
                    Working Stock
                </div>
                <div className={styles.leftBox}></div>
            </div>
            <div className={styles.masterWorkingBottomBox}>
                {/* 선택된 Line의 상세 Box 정보 표시 */}
                <div className={styles.vesselNo}>Vessel-{props.vessel}</div>
                <div>
                    <div className={styles.StockCategory}>
                        {isSelectedFirst ? 'Master Stock' : 'Working Stock'}
                    </div>
                    {organoidStatusToLine.filter(status => isSelectedFirst ? status.Master === '1' : status.Master === '0')
                        .map((lineStatus, index) => (
                            <div key={index}
                                className={`${styles.lineContainer} ${selectedLine === lineStatus.Line ? styles.selectedLineContainer : ''}`}
                                onClick={() => handleLineSelect(lineStatus.Line)}
                            >
                                <div className={`${styles.lineName} ${selectedLine === lineStatus.Line ? styles.selectedLine : ''}`}>
                                    {lineStatus.Line}
                                </div>
                                <div className={styles.percentageBarContainer}>
                                    <div className={`${styles.percentageBar} ${((lineStatus.count / 1300) * 100) > 97 ? styles.highValuePercentage : ''}`} style={{ width: `${Math.max(((lineStatus.count / 1300) * 100), 3)}%`, backgroundColor: isSelectedFirst ? '#E78E38' : 'green' }}></div>
                                    <span className={styles.percentageValue}>{((lineStatus.count / 1300) * 100).toFixed(0)}%</span>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={styles.boxDataSquare}>
                    {selectedLine && (
                        <>
                            {/* Master Stock 데이터 표시 */}
                            {isSelectedFirst && (
                                <div className={styles.stockSection}>
                                    <div className={styles.stockHeader}>Master Stock</div>
                                    {organoidStatusToBox
                                        .filter((box) => box.Line === selectedLine && box.Master === "1")
                                        .map((boxStatus, index) => (
                                            <div key={index} className={styles.boxDetail}>
                                                <div className={styles.boxName}>{boxStatus.Box}</div>
                                                <div className={styles.percentageBarContainer}>
                                                    <div
                                                        className={`${styles.percentageBar} ${boxStatus.count > 97 ? styles.highValuePercentage : ""
                                                            }`}
                                                        style={{
                                                            width: `${Math.max((boxStatus.count / 100) * 100, 3)}%`,
                                                            backgroundColor: "#E78E38",
                                                        }}
                                                    ></div>
                                                    <span className={styles.percentageValue}>
                                                        {((boxStatus.count / 100) * 100).toFixed(0)}%
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}

                            {/* Working Stock 데이터 표시 */}
                            {!isSelectedFirst && (
                                <div className={styles.stockSection}>
                                    <div className={styles.stockHeader}>Working Stock</div>
                                    {organoidStatusToBox
                                        .filter((box) => box.Line === selectedLine && box.Master === "0")
                                        .map((boxStatus, index) => (
                                            <div key={index} className={styles.boxDetail}>
                                                <div className={styles.boxName}>{boxStatus.Box}</div>
                                                <div className={styles.percentageBarContainer}>
                                                    <div
                                                        className={`${styles.percentageBar} ${boxStatus.count > 97 ? styles.highValuePercentage : ""
                                                            }`}
                                                        style={{
                                                            width: `${Math.max((boxStatus.count / 100) * 100, 3)}%`,
                                                            backgroundColor: "green",
                                                        }}
                                                    ></div>
                                                    <span className={styles.percentageValue}>
                                                        {((boxStatus.count / 100) * 100).toFixed(0)}%
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            )}
                        </>
                    )}
                </div>

            </div>
        </div>
    );
}

export default BankingStatistics;
