import React, { useState, useEffect } from 'react';
import styles from './DraftBankingDetailInfoComponent.module.css';

function DraftBankingDetailInfoComponent(props) {
    const [bankingData, setBankingData] = useState();
    const [updatedData, setUpdatedData] = useState({});
    console.log(props.selectedDraft);

    useEffect(() => {
        // Fetch original banking data
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                param: props.Key,
            }),
        };

        fetch('/api/getBankingInfo', reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    setBankingData(data[0]);
                }
            });

        // Parse tempQuery to extract updated fields and values
        const tempQuery = props.selectedDraft.tempQuery || '';
        const updates = {};
        const updateMatch = /SET (.+?) WHERE/i.exec(tempQuery);
        if (updateMatch) {
            const fields = updateMatch[1].split(',').map((field) => field.trim());
            fields.forEach((field) => {
                const [key, value] = field.split('=').map((item) => item.trim().replace(/['`]/g, ''));
                updates[key] = value;
            });
        }
        setUpdatedData(updates);
    }, [props.Key, props.selectedDraft]);

    const renderField = (field, label) => {
        const originalValue = bankingData[field] || 'N/A';
        const updatedValue = updatedData[field] || originalValue;
    
        return (
            <div className={styles.fieldRow}>
                <div className={styles.fieldLabel}>{label}</div>
                <div className={styles.fieldValue}>
                    {originalValue !== updatedValue ? (
                        <>
                            <span className={styles.originalValue}>{originalValue}</span> →{' '}
                            <span className={styles.updatedValue}>{updatedValue}</span>
                        </>
                    ) : (
                        originalValue
                    )}
                </div>
            </div>
        );
    };
    

    return (
        <>
            {bankingData && (
                <div className={styles.main}>
                    <div className={styles.draftBankingTitle}>Banking Information</div>
                    <div className={styles.bankingInfoInDraft}>
                        {renderField('PatientCode', 'Patient Code')}
                        {renderField('Master', 'Master')}
                        {renderField('LabCode', 'LabCode')}
                        {renderField('Vessel', 'Vessel')}
                        {renderField('Line', 'Line')}
                        {renderField('Box', 'Box')}
                        {renderField('Position', 'Position')}
                        {renderField('PassageNo', 'Passage No.')}
                        {renderField('Keeper', 'Keeper')}
                        {renderField('FreezingDate', 'Freezing Date')}
                        {renderField('domePerVialOrCellNumber', 'Dome/Vial or Cell number')}
                        {renderField('CultureMedia', 'Culture Media')}
                        {renderField('Mycoplasma', 'Mycoplasma')}
                        {renderField('thawingNo', 'Thawing No.')}
                        {renderField('annotation', 'Note')}
                        {renderField('Exporter', 'Exporter')}
                        {renderField('ExporteDate', 'Export Date')}
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftBankingDetailInfoComponent;
