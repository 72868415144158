import React, { useState, useEffect } from 'react';
import styles from './DraftPatientDetailInfoComponent.module.css';

function DraftPatientDetailInfoComponent(props) {
    const [patientData, setPatientData] = useState();

    useEffect(() => {
        let reqOption = {
            method: 'post',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                param: props.Key,
            }),
        };

        fetch('/api/getPatientInfo_noLog', reqOption)
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    setPatientData(data[0]);
                }
            });
    }, []);

    const renderField = (label, value) => (
        <div className={styles.fieldRow}>
            <div className={styles.fieldLabel}>{label}</div>
            <div className={styles.fieldValue}>{value}</div>
        </div>
    );

    return (
        <>
            {patientData && (
                <div className={styles.main}>
                    <div className={styles.draftTitle}>Patient Information</div>
                    <div className={styles.infoContainer}>
                        {renderField('Patient Code', patientData.PatientCode)}
                        {renderField('Origin', patientData.orgin)}
                        {renderField('Gender', patientData.Gender)}
                        {renderField('Age', patientData.Age)}
                        {renderField('Stage', patientData.Stage)}
                        {renderField('Histologic Type', patientData.HistologicType)}
                        {renderField(
                            'Treatment History',
                            patientData.TreatmentHistory?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))
                        )}
                        {renderField(
                            'Clinical Annotation',
                            patientData.clinicalAnnotation?.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                            ))
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default DraftPatientDetailInfoComponent;
